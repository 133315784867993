<template>
    <div
        class="uk-card-body uk-width-1-1 uk-margin-remove-top uk-margin-remove-bottom uk-margin-remove-right uk-padding-remove-top uk-padding-remove-bottom uk-padding-remove-right"
        style="border-radius: 15px"
    >
        <div uk-grid style="width: 100%; font-size: 14px;">
            <div class="uk-width-1-4@m uk-padding-small" style="background-color: #E6F4F8; border-radius: 15px 0px 0px 15px">
                <div class="uk-flex-inline uk-flex-middle">
                    <img class="profile-photo-small" :src="mitraData.photo_url ? mitraData.photo_url.file_url : `${images}/avatar.svg`" />
                    <div class="uk-margin-left uk-text-bold">{{ mitraData.fullname || '-' }}</div>
                </div>
                <hr class="uk-margin-small" />
                <div style="margin: 20px 0px">
                    <button
                        class="uk-button uk-width-1-1 uk-margin-remove uk-padding-remove uk-margin-small-top"
                        style="height: 40px; border-radius: 5px; background-color: #0ABAB5; color: white;"
                        @click="$router.push({ path: `/admin/workers/kyc/${mitraData._id}`, query: $route.query })"
                    >Details</button>
                </div>
                <div class="uk-text-truncate">
                    <p>
                        <img :src="`${images}/kyc/telephone-black.svg`" alt="" class="uk-margin-small-right">
                        <span>{{ mitraData.phone_number || '-' }}</span>
                    </p>
                    <p>
                        <img :src="`${images}/kyc/mail-black.svg`" alt="" class="uk-margin-small-right">
                        <span>{{ mitraData.email }}</span>
                    </p>
                    <p>
                        <img :src="`${images}/kyc/location-black.svg`" alt="" class="uk-margin-small-right">
                        <span>{{ mitraData.domicile_city || '-' }}</span>
                    </p>
                    <p>
                        <img :src="`${images}/kyc/id-card-black.svg`" alt="" class="uk-margin-small-right">
                        <span>{{ mitraData.identity_number || '-' }}</span>
                    </p>
                    <p>
                        <img :src="`${images}/kyc/tall-building-black.svg`" alt="" class="uk-margin-small-right">
                        <span>{{ mitraData.office }}</span>
                    </p>
                    <p>
                        <img :src="`${images}/kyc/worker-with-hat-black.svg`" alt="" class="uk-margin-small-right">
                        <span>{{ mitraData.role }}</span>
                    </p>
                </div>
            </div>
            <div class="uk-width-expand@m uk-padding-small" style="color: black">
                <div>
                    <div class="uk-flex" style="gap: 10px; min-height: 25px !important;">
                        <div
                            v-if="mitraData.silver_validation && mitraData.silver_validation.is_valid"
                            style="
                                background-color: #AEAEAE !important;
                                color: white;
                                border-radius: 5px;
                                padding: 1px 10px;
                            "
                        >
                            <img :src="`${images}/progress-check.svg`">
                            Validated as silver
                        </div>
                        <div
                            v-if="mitraData.gold_validation && mitraData.gold_validation.is_valid"
                            style="
                                background-color: #EAA800 !important;
                                color: white;
                                border-radius: 5px;
                                padding: 1px 10px;
                            "
                        >
                            <img :src="`${images}/progress-check.svg`">
                            Validated as gold
                        </div>
                        <div
                            v-if="mitraData.platinum_validation && mitraData.platinum_validation.is_valid"
                            style="
                                background-color: black;
                                color: white;
                                border-radius: 5px;
                                padding: 1px 10px;
                            "
                        >
                            <img :src="`${images}/progress-check.svg`">
                            Validated as platinum
                        </div>                        
                    </div>
                    <div class="uk-width-1-2@m" style="padding-top: 10px;">
                        <div
                            v-for="(mitraData, index) in workerContractData"
                            :key="index"
                            class="uk-width-1-1 uk-column-1-2@m uk-text-bold"
                            style="
                                border-radius: 10px;
                                margin-bottom: 3px
                            "
                        >
                            <p class="uk-margin-remove">{{ mitraData.label }}</p>
                            <p class="uk-margin-remove">{{ mitraData.value }}</p>
                        </div>
                    </div>
                </div>
                <hr class="uk-margin-small" />
                <div uk-grid>
                    <div class="uk-width-3-4@m">
                        <div
                            v-for="(data, index) in workerMainData"
                            :key="index"
                            class="uk-width-1-1 uk-margin-remove uk-padding-small"
                            uk-grid
                            style="
                                border-radius: 10px;
                                padding: 10px 0px;
                            "
                        >
                            <p class="uk-width-1-3@m uk-margin-remove uk-padding-remove-left">{{ data.label }}</p>
                            <p class="uk-width-expand uk-text-bold uk-text-truncate uk-margin-remove uk-padding-remove-left">{{ data.value }}</p>
                        </div>
                    </div>
                    <div class="uk-width-1-4@m uk-text-bold">
                        <p>KYC's main documents</p>
                        <p v-for="(doc, id) in kycMainDocs" :key="id" class="uk-margin-small">
                            <img
                                v-if="doc.status"
                                :src="`${images}/check-circle-d-green.svg`"
                                alt="File available"
                                class="uk-margin-small-right"
                            >
                            <img v-else :src="`${images}/cross-circle.svg`" alt="File not available" class="uk-margin-small-right">
                            {{ doc.label }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { PREFIX_IMAGE } from "@/utils/constant";
import formatter from "@/utils/formatter";
import moment from 'moment';

moment.locale('id');

export default {
    name: "MitraItemKYC",
    components: {},
    props: {
        mitraData: {
            type: Object,
            required: true,
            default: () => {}
        },
    },
    data() {
        return {
            images: PREFIX_IMAGE,
        };
    },
    computed: {
        workerContractData() {
            const startDate = this.mitraData.start_date ? formatter.dateComplete(moment(this.mitraData.start_date).toISOString()) : '-';
            const endDate = this.mitraData.start_date ? formatter.dateComplete(moment(this.mitraData.end_date).toISOString()) : '-';
            return [
                {
                    label: "Contract Start Date",
                    value: ": " + startDate
                },
                {
                    label: "Contract End Date",
                    value: ": " + endDate
                },
            ];
        },
        workerMainData() {
            return [
                {
                    label: "Mitra Full Name by MDM",
                    value: this.mitraData.fullname_ktp || '-'
                },
                {
                    label: "Address KTP (from PWA)",
                    value: this.mitraData.address || '-'
                },
                {
                    label: "Domicile Address by MDM",
                    value: this.mitraData.domicile_address || '-'
                },
                {
                    label: "Salary Amount/Unit",
                    value: this.mitraData.salary_amount || '-'
                },
                {
                    label: "NPWP Number",
                    value: this.mitraData.npwp || '-'
                },
                {
                    label: "Main Payment Method",
                    value: this.mitraData.payment_method || '-'
                },
                {
                    label: "Main Bank Name",
                    value: this.mitraData.bank_name || '-'
                },
                {
                    label: "Main Bank Account Name",
                    value: this.mitraData.bank_account_name || '-'
                },
                {
                    label: "Main Bank Number",
                    value: this.mitraData.bank_account_number || '-'
                },
            ];
        },
        kycMainDocs() {
            return [
                {
                    label: "KTP (from PWA)",
                    status: this.mitraData.kyc_docs.ktp_mitra,
                },
                {
                    label: "KTP by MDM",
                    status: this.mitraData.kyc_docs.ktp_mdm,
                },
                {
                    label: "Account Book (from PWA)",
                    status: this.mitraData.kyc_docs.bank_account_mitra,
                },
                {
                    label: "Account Book by MDM",
                    status: this.mitraData.kyc_docs.bank_account_mdm,
                },
                {
                    label: "NPWP (from PWA)",
                    status: this.mitraData.kyc_docs.npwp_mitra,
                },
                {
                    label: "NPWP by MDM",
                    status: this.mitraData.kyc_docs.npwp_mdm,
                },
            ];
        }
    },
    methods: {}
};
</script>
